import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { getConfig } from "./config";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const config = getConfig();
const providerConfig = {
  domain: config.domain || "",
  clientId: config.clientId || "",
  // onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin + "/sunnysdk",
    ...(config.audience ? { audience: config.audience } : {}),
  },
  redirectUri: window.location.origin + "/sunnysdk",
};
root.render(
  <BrowserRouter>
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>
  </BrowserRouter>
);

