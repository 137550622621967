import React, { useEffect, useRef, useState } from "react";
import BenefitsNav from "../Components/BenefitsNav";
import MainContainer from "../Components/MainContainer";
import WelcomeUser from "../Components/WelcomeUser";
import Services from "../Components/Services";
import BenefitsAccount from "../Components/BenefitsAccount";
import BenefitFocusSideBar from "../Components/BenefitFocusSideBar";
import {
  commnicatesWithHeliosUi,
  execInit,
  extractSubdomain,
  openHomePageWidgetBasedOnEnv,
  userName,
} from "../utils/Helpers/Functions";
import { useAuth0 } from "@auth0/auth0-react";
import HeliosSideBar from "../Components/HeliosSideBar";
interface MetaData {
  consumerCode?: string;
  // Add other properties if present in your actual code
}

const BenefitsFocusHome = () => {
  const { user, logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const [benefitFocusPageToggle, setBenefitFocusPageToggle] = useState(false);
  const [isIframeSiteOnHomePage,setIsIframeSiteOnHomePage] = useState({
    helpWidgetOpen:false,
    path:""
  })
  
  let isSideBarOpen = benefitFocusPageToggle ? "open" : "closed";
  const name = userName(user);
  const url = window.location.hostname;
 
  const subdomain = extractSubdomain(url);
  let metaData: MetaData | undefined;
  metaData = user && user["https://www.sunnyrewards.com/app_metadata"];
  useEffect(() => {
    if(!metaData || user == null){return;}
    let iframe: HTMLIFrameElement;
    let modalBody: HTMLDivElement;
    (async () => {
      const token = await getAccessTokenSilently().catch(() => "");
      execInit(subdomain, "1158px", metaData, token, user);
      // @ts-ignore
      iframe = document.getElementById("ifr1");
      // @ts-ignore
      modalBody = modalBodyRef.current;
      if (iframe && modalBody) {
        modalBody.appendChild(iframe);
        setBenefitFocusPageToggle(true);
      }
    })();
    return () => {
      if (iframe && modalBody) {
        modalBody.removeChild(iframe);
      }
    };
  }, [metaData]);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      console.log("event.data.message", event.data.message);
      if (event.data.message === "hide") {
        setBenefitFocusPageToggle(false);
      }
    });
  }, [])

  const handleToggle = () => {
    setBenefitFocusPageToggle(!benefitFocusPageToggle);
  };
  const handlePathChange = (data:any) => setIsIframeSiteOnHomePage({...data})
  
  useEffect(()=>{
    commnicatesWithHeliosUi(openHomePageWidgetBasedOnEnv(subdomain),handlePathChange)
  },[subdomain])
  return (
    <main className="benefits-home-page">
      <div className="row full-width-mobile">
        <div className={`wrapper-div ${isSideBarOpen}`}>
          <BenefitsNav
            setBenefitFocusPageToggle={() => {
              setBenefitFocusPageToggle(!benefitFocusPageToggle);
            }}
            name={name}
          />
          <MainContainer />
          <WelcomeUser
            name={name}
            benefitFocusPageToggle={benefitFocusPageToggle}
            isAuthenticated ={isAuthenticated}
          />
          <Services benefitFocusPageToggle={benefitFocusPageToggle} />
          <BenefitsAccount />
        </div>

        <div
          className={`${isSideBarOpen}`}
          style={{ display: isAuthenticated ? "block" : "none" }}
        >
          <HeliosSideBar
            handleToggle={handleToggle}
            toggle={benefitFocusPageToggle}
            modalBodyRef={modalBodyRef}
            isIframeSiteOnHomePage={isIframeSiteOnHomePage}
          />
        </div>
      </div>
    </main>
  );
};

export default BenefitsFocusHome;
